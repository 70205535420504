// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import giftCardLoginGirlArm from "@/public/_ssr/login-girl-arm.png";
import giftCardLoginGirlBody from "@/public/_ssr/login-girl-body.png";
import { Box, Image, Typography } from "@outschool/backpack";
import * as FilestackUrls from "@outschool/filestack-urls";
import {
  SignupModalQuery as SignupModalQueryType,
  SignupModalQueryVariables,
} from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import {
  getActivitySlugIdFromSlug,
  queryStringToObject,
} from "@outschool/routes";
import { IntegrationCategory, useTrackEvent } from "@outschool/ui-analytics";
import { useQueryWithPreviousData } from "@outschool/ui-apollo";
import {
  AuthTrigger,
  SignupModalQuery,
  useGetAuthReason,
  useLoginFlowContext,
  useSession,
} from "@outschool/ui-auth";
import { ReferrerHeader } from "@outschool/ui-components-website";
import {
  Container,
  Loading,
  Modal,
} from "@outschool/ui-legacy-component-library";
import { Screen } from "@outschool/ui-utils";
import React, { useState } from "react";

import { WithNextImageProp } from "./lib/SsrImage";
import SignupForm from "./SignupForm";

const MIN_PRICE = `$10 USD`;

export default function SignupModal() {
  const { authTrigger, isSignupModalOpen, exitLoginFlow, closeSignupModal } =
    useLoginFlowContext();

  const { isLoggedIn } = useSession();

  const searchParams =
    (typeof window !== "undefined" &&
      queryStringToObject(window.location.search, true)) ||
    {};

  const {
    activitySlug = "",
    teacherReferral: isTeacherReferral = false,
    isTeacherFlow = false,
    noClose: isNotCloseable = false,
    skipLearners: shouldSkipLearners = false,
    isGiftCardSignup = false,
    usid,
    uidOrLink,
  } = searchParams;

  // A querystring like ?usid=3&usid=3 can give us an array here instead of a string like we want
  // If this happens, just take the first value out of the array:
  const referrerSlugId = Array.isArray(usid) ? usid[0] : usid || null;

  const activitySlugId = getActivitySlugIdFromSlug(activitySlug);

  const { data: { activity, referrer, leader } = {}, loading } =
    useQueryWithPreviousData<SignupModalQueryType, SignupModalQueryVariables>(
      SignupModalQuery,
      {
        variables: {
          skipActivity: !activitySlugId,
          activitySlugId,
          referrerSlugId,
          skipReferrer: !referrerSlugId,
          skipLeader: !uidOrLink,
        },
        fetchPolicy: "no-cache",
        skip: !isSignupModalOpen || isLoggedIn,
      }
    );

  const leaderName = leader?.leader?.name;

  // This const is used to determine if the user is signing up for Outschool from the
  // navbar on desktop or from the mobile menu for the TeacherRegistrationLink experiment.
  // AuthTrigger.JOIN_OUTSCHOOL is used for signup except when the user is signing up via an
  // enrollment in a class. We also want to make sure that the user is not signing in as a teacher.
  const joinFromNavBar =
    authTrigger === AuthTrigger.JOIN_OUTSCHOOL && !isTeacherFlow;

  const [isRedirecting, setIsRedirecting] = useState(false);

  return (
    <Modal
      ariaLabel="Join Outschool"
      open={isSignupModalOpen}
      onClose={exitLoginFlow}
      fullBleed={false}
      hasCloseButton={!isNotCloseable}
      closeViaMaskClick={!isNotCloseable}
      closeViaEscButton={!isNotCloseable}
      iconButtonSx={{
        position: "relative",
        zIndex: 2,
      }}
      sx={{
        maxWidth: 475,
      }}
    >
      <Box data-test-id="signup-modal-content">
        {isRedirecting || loading ? (
          <Loading data-test-id="signup-modal-loading" />
        ) : (
          <>
            <SignupModalHeader
              {...{
                activity,
                referrer,
                leaderName,
                authTrigger,
                isTeacherFlow,
              }}
            />
            <SignupForm
              promptLogin
              onRedirect={closeSignupModal}
              isTeacherFlow={isTeacherReferral || isTeacherFlow}
              trackingUniqueId="signup-modal-signup-form"
              signupParams={{
                skipLearners: shouldSkipLearners,
                isGiftCardSignup,
              }}
              joinFromNavBar={joinFromNavBar}
              setIsRedirecting={setIsRedirecting}
            />
          </>
        )}
      </Box>
    </Modal>
  );
}

export function SignupModalHeader({
  authTrigger,
  referrer,
  activity,
  isTeacherFlow,
  leaderName,
}) {
  if (authTrigger === AuthTrigger.JOIN_OUTSCHOOL) {
    return <JoinForFreeHeader isTeacherPath={isTeacherFlow} />;
  }
  if (authTrigger) {
    return (
      <AuthTriggerHeader
        authTrigger={authTrigger}
        leaderName={leaderName}
        referrer={referrer}
      />
    );
  }
  if (referrer) {
    return <ReferrerHeader referrer={referrer} />;
  }

  if (activity) {
    return <ActivityHeader activity={activity} />;
  }

  return <DefaultHeader />;
}

function DefaultHeader() {
  const { t } = useTranslation("ssr-client\\components\\SignupModal");
  return (
    <div data-test-id="default-signup-header">
      <Box
        sx={{
          marginY: "1em",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: "0.5em",
          }}
        >
          {t("Join Outschool")}
        </Typography>
        {t("Amazing classes and teachers await!")}
      </Box>
    </div>
  );
}

function AuthTriggerHeader({ authTrigger, leaderName, referrer }) {
  const { t } = useTranslation("ssr-client\\components\\SignupModal");
  const isMobile = Screen.useIsMobile();
  const getAuthReason = useGetAuthReason();
  const authReason = getAuthReason(authTrigger);
  const trackEvent = useTrackEvent(IntegrationCategory.Advertising);
  React.useEffect(() => {
    trackEvent("Visit sign up - parent", { authReason });
  }, [authReason, trackEvent]);

  return (
    <Container
      sx={{
        display: "grid",
        gap: "small",
        justifyItems: "center",
        marginBottom: "medium",
      }}
      data-test-id="signup-modal-auth-trigger-header"
    >
      {authTrigger === AuthTrigger.ENROLL ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            variant={isMobile ? "h3" : "h2"}
            sx={{
              textAlign: "center",
              paddingTop: "1em",
            }}
          >
            {t("Join to Enroll")}
          </Typography>
        </Box>
      ) : authTrigger === AuthTrigger.REDEEM_GIFT_CARD ||
        authTrigger === AuthTrigger.BUY_GIFT_CARD ? (
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left: "-32px",
              width: "calc(100% + 64px)",
              top: "-16px",
              height: "322px",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                borderRadius: "0.5em",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "1072px",
                  height: "1147px",
                  bottom: "0px",
                  left: "calc(50% - 536px)",
                  borderRadius: "50%",
                  background:
                    "linear-gradient(263.04deg, #E4FDFF 0%, #FFF8E8 100%)",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "371px",
                    height: "398px",
                    left: "calc(50% - 419px)",
                    bottom: "-185px",
                    background: "rgba(41, 168, 119, 0.35)",
                    borderRadius: "50%",
                    opacity: 0.5,
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    width: "97.08px",
                    height: "104.04px",
                    left: "calc(50% + 150px)",
                    bottom: "260px",
                    background: "rgba(43, 167, 206, 0.2)",
                    borderRadius: "50%",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Typography
            variant="h3"
            sx={{
              position: "relative",
              textAlign: "center",
              paddingTop: "1em",
            }}
          >
            {t("Join to {{authReason}}", { authReason })}
          </Typography>
          <Box
            sx={{
              position: "relative",
              textAlign: "center",
              width: "100%",
            }}
          >
            {t(
              "Outschool offers thousands of live online classes for kids ages 3–18."
            )}
          </Box>
          <Box
            sx={{
              position: "relative",
              margin: "auto",
              height: "225px",
              width: "225px",
            }}
          >
            <Image<WithNextImageProp>
              sx={{ position: "absolute" }}
              height={200}
              width={200}
              nextImage={{ src: giftCardLoginGirlBody }}
              alt={"Gift card logo"}
            />
            <Image<WithNextImageProp>
              sx={{ position: "absolute", top: "5px", left: 4 }}
              width={200}
              height={220}
              nextImage={{ src: giftCardLoginGirlArm }}
              alt={"Gift card logo"}
            />
          </Box>
        </Box>
      ) : referrer ? (
        <Box
          sx={{
            marginTop: "1em",
          }}
        >
          <ReferrerHeader referrer={referrer} />
        </Box>
      ) : (
        <Box>
          <Typography
            variant={isMobile ? "h3" : "h2"}
            sx={{
              textAlign: "center",
              paddingTop: "1em",
            }}
          >
            {t("Join to {{authReason}}", {
              authReason: leaderName
                ? authReason.replace("this teacher", leaderName)
                : authReason,
            })}
          </Typography>
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
            }}
          >
            {t("Free to join, pay as low as {{minimumPrice}} per class.", {
              minimumPrice: MIN_PRICE,
            })}
          </Box>
        </Box>
      )}
    </Container>
  );
}

function JoinForFreeHeader({ isTeacherPath }) {
  const { t } = useTranslation("ssr-client\\components\\SignupModal");
  const isMobile = Screen.useIsMobile();
  const getAuthReason = useGetAuthReason();
  const authReason = getAuthReason(AuthTrigger.JOIN_OUTSCHOOL);

  const trackEvent = useTrackEvent(IntegrationCategory.Advertising);
  React.useEffect(() => {
    if (isTeacherPath) {
      trackEvent("Visit sign up - teacher", { authReason });
    } else {
      trackEvent("Visit sign up - parent", { authReason });
    }
  }, [authReason, isTeacherPath, trackEvent]);

  return (
    <Box
      flex
      sx={{
        display: "grid",
        gap: "0.5em",
        justifyItems: "center",
        marginBottom: "2em",
      }}
      data-test-id="signup-modal-join-free-header"
    >
      <Typography
        variant={isMobile ? "h3" : "h2"}
        sx={{
          textAlign: "center",
          paddingTop: "1em",
        }}
      >
        {isTeacherPath ? t("Teach on your terms") : t("Invest in their future")}
      </Typography>
      {!isTeacherPath && (
        <Box
          sx={{
            textAlign: "center",
            width: "100%",
          }}
        >
          {isTeacherPath
            ? t`Set your schedule, share your passion, and reach motivated learners worldwide.`
            : t`Get personalized classes tailored to your kid's learning style that set them up for success.`}
        </Box>
      )}
    </Box>
  );
}

type ActivityHeaderProps = {
  activity: Pick<SignupModalQueryType["activity"], "title" | "details">;
  referrer?: SignupModalQueryType["referrer"];
  title?: string;
  subtitle?: string;
};

export function ActivityHeader({
  activity,
  title,
  subtitle,
}: ActivityHeaderProps) {
  const { t } = useTranslation("ssr-client\\components\\SignupModal");

  title = title ?? t("Join Outschool");

  subtitle = subtitle || t("Discover amazing classes like:");
  const imageUrl = FilestackUrls.imageUrl(activity.details.photo, {
    w: 375,
    h: 198,
    fit: "crop",
  });
  return (
    <div data-test-id="signup-modal-activity-header">
      <Box
        sx={{
          marginBottom: "1em",
          textAlign: "center",
        }}
      >
        <div>
          <Typography
            variant="h3"
            sx={{
              marginBottom: "0.5em",
            }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              marginBottom: "1em",
            }}
          >
            {subtitle}
          </Box>
          <Image
            src={imageUrl}
            height={200}
            width={380}
            alt="class cover"
            sx={{
              width: "100%",
              boxSizing: "border-box",
              borderRadius: "4px",
              marginBottom: "1em",
            }}
          />
          <Box
            sx={{
              fontWeight: 500,
              color: "#808080",
              marginBottom: "1em",
            }}
          >
            {activity.title}
          </Box>
        </div>
      </Box>
    </div>
  );
}
